<template>
	<div class="video-player" :style="'--plyr-color-main:' + color">
		<!-- data-poster="poster.jpg" -->
		<video
			controls
			playsinline
			ref="video"
		>
			<source :src="src" type="video/mp4"/>
		</video>

		<div class="video-player__actions">
			<v-btn
				v-if="showSelect"
				height="32"
				width="32"
				min-width="20"
				color="success"
				class="rounded-xl px-2 me-4"
				@click="$emit('select', src)"
			>
				<v-icon size="22" color="white">mdi-check</v-icon>
			</v-btn>

			<v-btn
				v-if="showDelete"
				height="32"
				width="32"
				min-width="20"
				color="red lighten-1"
				class="rounded-xl px-2"
				@click="$emit('remove', src)"
			>
				<v-icon size="22" color="white">mdi-delete</v-icon>
			</v-btn>
		</div>
	</div>
</template>

<script>
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'
import { mainColor } from '@/configs/brand.config';
export default {
	name: 'VideoPlayer',
	
	props: {
		src: { type: String },
		color: { type: String, default: mainColor },
		mainControls: { type: Boolean, default: false },
		showDelete: { type: Boolean, default: false },
		showSelect: { type: Boolean, default: false },
	},

	data: () => ({
		player: null,
	}),

	watch: {
		src(newVal) {
			if (newVal) {
				this.$refs.video.load();
			}
		}
	},

	computed: {
		options() {
			let controls = [
				'play-large',
				'play',
				'progress',
				'current-time',
				'mute',
				'captions',
				'settings',
				'pip',
				'airplay',
				'fullscreen'
			];
			if (this.mainControls) {
				controls = [
					'play',
					'progress',
					'current-time',
					'fullscreen'
				]
			}
			return {
				controls,
				speed: { selected: 1, options: [0.5, 1, 1.5] }
			}
		}
	},

	updated() {
		this.player = new Plyr(this.$refs.video, this.options);
	},

	mounted() {
		this.player = new Plyr(this.$refs.video, this.options);
	},

	beforeDestroy() {
		this.player.pip = false;
		this.player.stop();
	}
}
</script>

<style lang="scss">
.video-player {
	position: relative;

	.plyr {
		border-radius: 11px;
	}
	
	&__actions {
		opacity: 0;
		visibility: hidden;

		transition: all .3s;

		position: absolute;
		top: 45%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&:hover &__actions {
		opacity: 1;
		visibility: visible;
	}
}
</style>