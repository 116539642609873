export default {
	'change-settings': 'change settings',
	'points-settings': 'points settings',

	'points-balance': 'Points balance',
	'expire-in': 'Expire in',
	'total-earned-points': 'Total Earned points',
	'total-spent-points': 'Total Spent points',
	'lost-points': 'Lost points',

	'please-select-user': 'Please select a customer',

	spent: 'Spent',
	earned: 'Earned',
}