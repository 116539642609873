import { useRestore } from '@/configs/global.config';
import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		customServices: []
	},

	actions: {
		fetchAll({ commit }, { type, typeName, useOrder }) {
			return axios.get('/custom-services', {
				params: { type, typeName, useOrder }
			}).then(({ data }) => {
				const storeData = data.filter(c => !useRestore.customServices ? !c.deletedAt : true);
				commit(types.STORE_CUSTOM_SERVICES, storeData);
				return storeData;
			})
		},

		fetchBySlug({ commit }, { slug, type, typeName, acceptLanguage }) {
			const configs = {
				params: { type, typeName },
				...headerAcceptLanguage(acceptLanguage)
			};
			return axios.get(`/custom-services/${slug}`, configs).then(({ data }) => data);
		},

		create({ commit }, { type, typeName, title, summary, code, content, descriptionSeo, keywordsSeo, date, isFeatured, inHomePage, image, imagePath, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();

			const requestData = { type, typeName, title, summary, code, content, descriptionSeo, keywordsSeo, date, image, imageName };

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			});
			
			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			formData.append('isFeatured', isFeatured ? 1 : 0);
			formData.append('inHomePage', inHomePage ? 1 : 0);

			return axios.post('/custom-services', formData, configs).then(({ data }) => data);
		},
		
		update({ commit }, { id, type, typeName, title, summary, code, content, descriptionSeo, keywordsSeo, date, isFeatured, inHomePage, image, imagePath, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			
			const requestData = { type, typeName, title, summary, code, content, descriptionSeo, keywordsSeo, date, image, imageName };

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			});
			
			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));
			
			formData.append('isFeatured', isFeatured ? 1 : 0);
			formData.append('inHomePage', inHomePage ? 1 : 0);

			formData.append('_method', 'PUT');

			return axios.post(`/custom-services/${id}`, formData, configs).then(({ data }) => data);
		},

		uploadImages({ commit }, { id, type, typeName, images, imagesPaths, imagesNames }) {
			const formData = new FormData();

			formData.append('type', type);
			formData.append('typeName', typeName);

			images.forEach(image => {
				formData.append('images[]', image)
			});
			imagesPaths.forEach(path => {
				formData.append('imagesPaths[]', path.slice(path.indexOf('/', 1)));
			});
			imagesNames.forEach(name => {
				formData.append('imagesNames[]', name);
			});

			return axios.post(`/custom-services/${id}/add-images`, formData)
		},

		deleteImage({ commit }, { id, type, typeName, imageId }) {
			return axios.delete(`/custom-services/${id}/images/${imageId}`, {
				params: { type, typeName }
			})
		},

		changeOrder({ commit }, { id, type, typeName, order }) {
			return axios.post(`/custom-services/${id}/change-order`, {
				num: order,
				type,
				typeName,
				_method: 'PUT'
			});
		},

		delete({ commit }, { id }) {
			return axios.delete(`/custom-services/${id}`)
		},

		restore({ commit }, { id }) {
			return axios.patch(`/custom-services/${id}`)
		},
	},

	mutations: {
		[types.STORE_CUSTOM_SERVICES](state, customServices) {
			state.customServices = customServices;
		}
	},

	getters: {
		getServiceById: state => id => state.customServices.find(c => c.id === id),
		getServiceBySlug: state => slug => state.customServices.find(c => c.slug === slug),
	}
}