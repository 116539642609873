<template>
	<div>
		<!-- preview dialog -->
		<v-dialog v-model="previewDialog" max-width="500">
			<v-card>
				<v-card-text class="d-flex justify-center align-center pa-0">
					<v-btn
						fab
						small
						absolute
						left
						:disabled="previewUrlIndex === 0"
						@click="previewUrlIndex--"
						style="z-index: 1"
					>
						<v-icon size="24">mdi-chevron-{{$vuetify.rtl ? 'right' : 'left'}}</v-icon>
					</v-btn>
					<v-img
						contain
						:src="BASE_URL + filteredImages[previewUrlIndex]?.publicPath"
						transition="scale-transition"
					></v-img>
					<v-btn
						fab
						small
						absolute
						right
						:disabled="previewUrlIndex === filteredImages.length - 1"
						@click="previewUrlIndex++"
						style="z-index: 1"
					>
						<v-icon size="24">mdi-chevron-{{$vuetify.rtl ? 'left' : 'right'}}</v-icon>
					</v-btn>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="fileManager"
			:ids="deleteId"
			@on-delete="fetchFiles(true)"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('file-manager.the-item')}}
		</bee-delete-dialog>

		<!-- alt text dialog -->
		<v-dialog v-model="altTextDialog" max-width="350" content-class="rounded-medium">
			<v-form @submit.prevent="saveAltText" ref="altForm" :disabled="submitAltLoading">
				<v-card>
					<v-card-title class="justify-center primary--text text-h6 pb-0">
						{{$t('file-manager.alt-text')}}
					</v-card-title>
					<v-card-text class="rounded-medium pb-0">
						<v-row dense class="ma-0">
							<v-col cols="12">
								<v-text-field
									v-model="altText"
									:label="$t('inputs.alt-text')"
									outlined
									dense
									hide-details
									class="rounded-medium"
									:rules="rules.required"
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions class="justify-end">
						<v-btn
							type="submit"
							color="primary"
							class="rounded-medium"
							:loading="submitAltLoading"
						>{{$t('save')}}</v-btn>
						<v-btn
							class="rounded-medium"
							:disabled="submitAltLoading"
							@click="altTextDialog = false"
						>{{$t('cancel')}}</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- content -->
		<v-card
			:flat="portable"
			:loading="loading"
			:loader-height="portable ? 3 : 4"
			:class="{'rounded-medium': !portable, 'rounded-0': portable}"
		>
			<v-card-text :class="{ 'px-0 pb-0': portable }">
				<!-- tabs -->
				<v-tabs
					v-model="tab"
					:vertical="$vuetify.breakpoint.smAndUp"
					center-active
					show-arrows
					active-class="deep-purple lighten-5 rounded"
				>
					<!-- images -->
					<template v-if="showImages">
						<v-tab class="justify-start">
							<v-icon class="me-3">mdi-image-multiple</v-icon>
							{{$t('file-manager.tabs.images')}}
						</v-tab>
						
						<v-tab-item class="px-4 py-2">
							<!-- images filters -->
							<div v-if="images.length" class="d-flex flex-wrap algin-center mb-2">
								<v-chip-group
									v-model="collectionFilter"
									mandatory
									center-active
									show-arrows
									active-class="primary"
									class="text-capitalize"
								>
									<v-chip
										v-for="(filter, index) in collectionsFilters"
										:key="index"
										small
										:value="index"
									>{{filter}}</v-chip>
								</v-chip-group>

								<v-spacer/>
								
								<!-- upload image -->
								<tooltip :text="$t('tooltip.upload')">
									<v-btn icon @click="goToUploadTab('image')">
										<v-icon>mdi-tray-arrow-up</v-icon>
									</v-btn>
								</tooltip>

								<!-- image resize -->
								<v-menu left offset-x>
									<template v-slot:activator="{ attr, on }">
										<v-tooltip top color="grey darken-2" content-class="px-2 py-0">
											<template v-slot:activator="{ attr: tAttr, on: tOn }">
												<v-btn
													icon
													v-bind="{...attr, ...tAttr}"
													v-on="{...on, ...tOn}"
												>
													<v-icon size="22">mdi-view-dashboard</v-icon>
												</v-btn>
											</template>
											{{$t('tooltip.change-size')}}
										</v-tooltip>
									</template>

									<v-card flat color="px-4 pt-0 pb-4" width="160">
										<v-slider
											v-model="imagesResize"
											:max="2"
											:tick-labels="['sm', 'md', 'lg']"
											dense
											hide-details
										></v-slider>
									</v-card>
								</v-menu>

								<!-- refresh -->
								<tooltip :text="$t('tooltip.refresh')">
									<v-btn
										icon
										:disabled="loading"
										@click="fetchFiles(true)"
									>
										<v-icon size="22">mdi-sync</v-icon>
									</v-btn>
								</tooltip>
							</div>
							
							<v-layout v-if="images.length" wrap align-center>
								<v-flex
									v-for="(image, index) in filteredImages"
									:key="image.id"
									v-bind="imagesSize"
									class="fileManager__file d-sm-block d-flex justify-center mb-2 pe-sm-2"
								>
									<image-card
										:src="BASE_URL + image.publicPath"
										:show-select="portable"
										:show-alt="showSEO"
										:show-cancel="
											portable &&
											multipleSelect &&
											multipleSelectedFiles
												.map(c => c.publicPath)
												.includes(image.publicPath)
										"
										:large="imagesResize === 2"
										:class="{
											'fileManager__file--selected':
												selectedFile === image.publicPath ||
												(
													multipleSelect &&
													multipleSelectedFiles
														.map(c => c.publicPath)
														.includes(image.publicPath)
												)
										}"
										@preview="previewDialog = true; previewUrlIndex = index"
										@remove="deleteId = image.id; deleteDialog = true;"
										@select="selectFile(image, multipleSelect)"
										@cancel="removeSelectFile(image, multipleSelect)"
										@alt-clicked="altTextImage = image; altTextDialog = true;"
									/>
								</v-flex>

								<v-flex v-if="multipleSelect" class="fileManager__multiple-file">
									<v-btn
										:disabled="!multipleSelectedFiles.length"
										color="darkGreen white--text"
										class="rounded-medium"
										@click="$emit('multiple-select-confirm')"
									>
										<v-icon class="me-1">mdi-check</v-icon>
										{{$t('confirm')}}
									</v-btn>
								</v-flex>
							</v-layout>
						
							<p v-else class="text-uppercase text-body-1 ma-0">
								{{ loading ? $t('file-manager.loading') : $t('file-manager.no-images')}}
							</p>
						</v-tab-item>
					</template>

					<!-- videos -->
					<template v-if="showVideos">
						<v-tab class="justify-start">
							<v-icon class="me-3">mdi-movie-open</v-icon>
							{{$t('file-manager.tabs.videos')}}
						</v-tab>
						
						<v-tab-item class="px-4 py-2">
							<v-layout v-if="videos.length" wrap align-center>
								<v-flex
									v-for="video in videos"
									:key="video.id"
									lg3 md4 sm6 xs12
									class="mb-2 pe-2"
								>
									<video-player
										:src="BASE_URL + video.publicPath"
										main-controls
										show-delete
										:show-select="portable"
										:class="{ 'fileManager__file--selected': selectedFile === video.publicPath }"
										@select="selectFile(video)"
										@remove="deleteId = video.id; deleteDialog = true;"
									/>
								</v-flex>
							</v-layout>
						
							<p v-else class="text-uppercase text-body-1 ma-0">
								{{ loading ? $t('file-manager.loading') : $t('file-manager.no-videos')}}
							</p>
						</v-tab-item>
					</template>

					<!-- files -->
					<template v-if="showFiles">
						<v-tab class="justify-start">
							<v-icon class="me-3">mdi-file-multiple</v-icon>
							{{$t('file-manager.tabs.files')}}
						</v-tab>
						
						<v-tab-item class="px-4 py-2">
							<template v-if="files.length">
								<div class="d-flex">
									<!-- file filters -->
									<v-chip-group
										v-model="fileFilter"
										mandatory
										show-arrows
										center-active
										active-class="primary"
										class="mb-2 text-capitalize"
									>
										<v-chip small :value="0">all</v-chip>
										<v-chip
											v-for="(filter, index) in filesFilters"
											:key="index"
											small
											:value="index + 1"
										>{{filter}}</v-chip>
									</v-chip-group>
									
									<v-spacer/>

									<!-- upload file -->
									<v-btn icon @click="goToUploadTab('file')">
										<v-icon>mdi-tray-arrow-up</v-icon>
									</v-btn>
								</div>
								
								<!-- files -->
								<v-layout wrap align-center>
									<v-flex
										v-for="file in files.filter(c =>
											!this.fileFilter
											? true
											: c.fileName.includes(this.filesFilters[this.fileFilter - 1])
										)"
										:key="file.id"
										lg3 sm6 xs12
										class="mb-2 pe-2"
									>
										<file-card
											:src="BASE_URL + file.publicPath"
											:name="file.name"
											:show-select="portable"
											:class="{ 'fileManager__file--selected': selectedFile === file.publicPath }"
											@select="selectFile(file)"
											@remove="deleteId = file.id; deleteDialog = true;"
										/>
									</v-flex>
								</v-layout>
							</template>
						
							<p v-else class="text-uppercase text-body-1 ma-0">
								{{ loading ? $t('file-manager.loading') : $t('file-manager.no-files')}}
							</p>
						</v-tab-item>
					</template>

					<!-- upload -->
					<v-tab>
						<v-icon class="me-3">mdi-tray-arrow-up</v-icon>
						{{$t('file-manager.tabs.upload')}}
					</v-tab>

					<v-tab-item class="py-2 ps-sm-7">
						<v-chip-group
							v-model="uploadType"
							mandatory
							active-class="primary"
							class="mb-2 text-capitalize"
							@change="uploadFile = null"
						>
							<v-chip
								v-for="(type, index) in [
									showImages ? $t('file-manager.upload-type.image') : null,
									showVideos ? $t('file-manager.upload-type.video') : null,
									showFiles ? $t('file-manager.upload-type.file') : null
								]"
								v-show="type"
								:key="index"
								small
								:value="index"
							>{{type}}</v-chip>
						</v-chip-group>

						<v-form ref="form" @submit.prevent="upload">
							<!-- drop image -->
							<template v-if="uploadType === 0">
								<bee-drop-img
									v-model="uploadFile"
									radius="11"
									border-width="2"
									:border-color="isSubmitted && !uploadFile ? '#f00' : '#ccc'"
									:width="$vuetify.breakpoint.mdAndUp ? '65%' : '100%'"
									dashed
									height="250px"
									blurable
									:clearable="!isUploading"
									:hide-image-name="false"
									class="mb-4"
									@input="uploadFile ? totalSize = (uploadFile.size / 1024 ** 2).toFixed(2) : null"
								/>
								<v-row dense class="mb-2">
									<v-col md="6" cols="12">
										<v-text-field
											v-model="altText"
											:label="$t('inputs.alt-text')"
											outlined
											dense
											hide-details
											class="rounded-medium"
										></v-text-field>
									</v-col>
								</v-row>
							</template >
							
							<!-- video -->
							<template v-if="uploadType === 1">
								<div
									v-if="!isUploading"
									@dragover.prevent
									@drop.prevent="setVideo"
									style="max-width: 400px"
								>
									<v-file-input
										v-model="uploadFile"
										:label="$t('inputs.video')"
										outlined
										dense
										accept=".mp4"
										class="rounded-small"
										truncate-length="35"
										:clearable="false"
										@change="changeVideo()"
										:suffix="totalSize + ' MB'"
										:rules="rules.video"
									></v-file-input>
								</div>
							
								<!-- video preview -->
								<video-player
									v-if="uploadFile"
									:src="previewUrl"
									class="mb-6"
									style="max-width: 600px"
								/>
							</template>
							
							<!-- file -->
							<v-file-input
								v-if="uploadType === 2 && !isUploading"
								v-model="uploadFile"
								:label="$t('inputs.file')"
								outlined
								dense
								accept=".doc,.docx,.xls,.xlsx,.txt,.ppt,.pptx,.pdf,.pdf"
								class="rounded-small"
								truncate-length="35"
								:clearable="false"
								:suffix="totalSize + ' MB'"
								:rules="rules.file"
								style="max-width: 400px"
								@change="totalSize = (uploadFile.size / 1024 ** 2).toFixed(2)"
							></v-file-input>
							
							<!-- progress -->
							<div v-if="isUploading" class="d-flex align-center mb-6">
								<v-progress-circular
									:value="uploadedPercent"
									size="55"
									color="primary"
									class="ms-2"
									:class="{ 'loop-progress': isUploading }"
								>
									{{uploadedPercent}}%
								</v-progress-circular>
								<p class="ms-4 mb-0">
									<span class="d-block">
										{{uploadFileName}}
									</span>
									<span class="d-block">
										{{`${uploadedSize} / ${totalSize} MB`}}
									</span>
								</p>
							</div>
							
							<!-- upload button -->
							<v-btn
								type="submit"
								color="primary"
								class="rounded-medium"
								:disabled="!uploadFile || isUploading"
								@click="isSubmitted = true"
							>
								{{$t('upload')}}
							</v-btn>
						</v-form>
					</v-tab-item>
				</v-tabs>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import ImageCard from '@/components/file-manager/ImageCard.vue'
import FileCard from '@/components/file-manager/FileCard.vue'
import { mapGetters, mapState } from 'vuex'
import { SEO, videos } from '@/configs/routes.config'
import { useFileUpload } from '@/configs/global.config'
import { BASE_URL } from '@/helpers/constants'
import rules from '@/helpers/validation rules'

export default {
	name: 'FilesManger',

	props: {
		tabs: {
			type: Object,
			default: () => ({
				images: true,
				videos: true,
				files: true,
			}) 
		},
		portable: { type: Boolean, default: false },
		multipleSelect: { type: Boolean, default: false },
		value: { type: [String, Array] }
	},

	components: {
		ImageCard,
		FileCard,
	},

	data() {
		return {
			// helpers
			tab: 0,
			imagesResize: 0,

			previewDialog: false,
			previewUrlIndex: null,
			
			deleteDialog: false,
			deleteId: null,

			submitAltLoading: false,
			altTextDialog: false,
			altText: null,
			altTextImage: null,
			
			isSubmitted: false,

			loading: false,
			fileFilter: null,
			collectionFilter: null,
			uploadType: null,
			
			BASE_URL,
			rules,

			// configs
			showSEO: SEO.show,

			// uploading
			isUploading: false,
			uploadedSize: 0,
			totalSize: 0,
			uploadedPercent: 0,
			previewUrl: null,

			// data
			selectedFile: null,
			uploadFile: null,

			multipleSelectedFiles: [],
		}
	},

	watch: {
		previewDialog(val) {
			if (!val) {
				this.previewUrlIndex = null;
			}
		},

		altTextDialog(val) {
			if (val) {
				if (this.altTextImage) {
					this.altText = this.altTextImage.altText;
				}
			} else {
				this.$refs.altForm.reset();
				this.altTextImage = null;
			}
		},

		'$route.name'() {
			this.tab = 0;
			this.collectionFilter = 0;
		},

		value(newVal) {
			if (!newVal) {
				this.selectedFile = null;
				this.multipleSelectedFiles = [];
			}
		}
	},

	computed: {
		...mapState({
			allFiles: state => state.fileManager.files
		}),

		...mapGetters({
			getFileById: 'fileManager/getFileById'
		}),

		// configs
		showImages() {
			return this.tabs.images;
		},
		showVideos() {
			return videos.show && this.tabs.videos;
		},
		showFiles() {
			return useFileUpload && this.tabs.files;
		},

		// content
		imagesSize() {
			switch (this.imagesResize) {
			case 0: return { lg2: true, md3: true, sm4: true, xs12: true }
			case 2: return { lg4: true, md6: true, sm12: true, xs12: true }
			case 1:
			default: return { lg3: true, md4: true, sm6: true, xs12: true }
			}
		},

		images() {
			return this.allFiles.filter(c => /\.(jpe?g|png)/.test(c.fileName));
		},
		videos() {
			return this.allFiles.filter(c => c.collection === 'video');
		},
		files() {
			return this.allFiles.filter(c => c.collection.includes('file'));
		},

		// filters
		collectionsFilters() {
			return this.allFiles
				.map(c => c.collection)
				.filter(c => !c.includes('file') && c !== 'video')
				.filter((c, index, arr) => arr.indexOf(c) === index);
		},

		filesFilters() {
			return this.files
				.map(c => c.fileName.split('.')[1])
				.filter((c, index, arr) => {
					return arr.indexOf(c) === index;
				})
		},

		// images
		filteredImages() {
			return this.images.filter(c => this.collectionsFilters.indexOf(c.collection) === this.collectionFilter)
		},

		// image & file & video name
		uploadFileName() {
			if (this.uploadFile) {
				const splittedStr = this.uploadFile.name.split('.');
				const fileExtension = splittedStr[splittedStr.length - 1].toLowerCase();
				splittedStr.splice(splittedStr.length - 1, 1);
				const name = splittedStr.join();
				return (name.length > 65 ? name.slice(0, 65) + '...' : name) + '.' + fileExtension;
			} else {
				return null;
			}
		}
	},

	methods: {
		fetchFiles(forceFetch = false) {
			if (!this.allFiles.length || forceFetch) {
				this.loading = true;
				return this.$store.dispatch('fileManager/fetchAll').finally(() => {
					this.loading = false;
				})
			}
		},

		goToUploadTab(type) {
			const states = [this.showImages, this.showVideos, this.showFiles];
			const totalTabsCount = states.reduce((val, c) => c ? val + 1 : val, 0);
			
			switch (type) {
			case 'image': {
				this.uploadType = 0;
				break
			}
			case 'video': {
				this.uploadType = states[0] ? 1 : 0;
				break
			}
			case 'file': {
				this.uploadType = totalTabsCount - 1;
				break
			}
			}
			this.tab = totalTabsCount;
		},
		
		selectFile(file, useMultipleSelect = false) {
			if (this.portable) {
				if (useMultipleSelect) {
					this.multipleSelectedFiles.push(file);
					this.$emit('input', this.multipleSelectedFiles);
				} else {
					this.selectedFile = file.publicPath;
					this.$emit('input', file);
				}
			}
		},

		removeSelectFile(image, useMultipleSelect) {
			if (this.portable && useMultipleSelect) {
				const selectedFileIndex =
					this.multipleSelectedFiles.findIndex(c => c.publicPath === image.publicPath);
				if (selectedFileIndex !== -1) {
					this.multipleSelectedFiles.splice(selectedFileIndex, 1);
					this.$emit('input', this.multipleSelectedFiles);
				}
			}
		},

		// video
		changeVideo() {
			if (this.uploadFile) {
				this.totalSize = (this.uploadFile.size / 1024 ** 2).toFixed(2);
				this.previewUrl = URL.createObjectURL(this.uploadFile);
			} else {
				this.totalSize = 0;
			}
		},

		// submit methods
		upload() {
			this.isSubmitted = true;
			if (this.$refs.form.validate() && this.uploadFile) {
				this.$emit('start-upload');

				const actionName = this.uploadType === 0 ? 'uploadImage' : 'uploadFile';

				const progressHandler = (progressEvent) => {
					const uploadedBytes = progressEvent.loaded;
					const totalBytes = progressEvent.total;
					this.uploadedSize = (progressEvent.loaded / 1024 ** 2).toFixed(2);
					this.uploadedPercent = Math.trunc((uploadedBytes / totalBytes) * 100);
				}

				this.isUploading = true;
				this.$store.dispatch(`fileManager/${actionName}`, {
					image: this.uploadFile,
					file: this.uploadFile,
					progressHandler
				}).then(() => {
					this.uploadedSize = 0;
					this.totalSize = 0;
					this.uploadedPercent = 0;
					this.previewUrl = null;
					this.uploadFile = null;
					this.fetchFiles(true);

					this.tab = this.uploadType;
					if (this.uploadType === 0) {
						this.collectionFilter = this.collectionsFilters.indexOf('images');
						
						this.submitAltText();
					} else {
						this.collectionFilter = 0;
					}
				}).finally(() => {
					this.$emit('end-upload');
					this.isSubmitted = false;
					this.isUploading = false;
				})
			}
		},

		submitAltText(imageId) {
			if (this.altText) {
				return new Promise((resolve) => {
					setTimeout(() => {
						resolve()
					}, 1000);
				}).then(() => {
					this.fetchFiles(true);
				})
			}
		},

		saveAltText() {
			if (this.$refs.altForm.validate()) {
				this.submitAltLoading = true;
				this.submitAltText().then(() => {
					this.altTextDialog = false;
					this.submitAltLoading = false;
				});
			}
		},

		updateUploadType() {
			if (!this.showImages) {
				if (this.showVideos) {
					this.uploadType = 1;
				} else {
					this.uploadType = 2;
				}
			}
		},

		beforeUnloadListener(event) {
			if (this.isUploading) {
				event.preventDefault();
				event.returnValue = '';
				return this.$t('messages.uploading');
			}
		}
	},

	updated() {
		if (!this.value) {
			this.selectedFile = null;
			this.updateUploadType();
		}
	},

	created() {
		this.fetchFiles();

		this.updateUploadType();
	},
	
	mounted() {
		window.addEventListener('beforeunload', this.beforeUnloadListener);
	},

	beforeDestroy() {
		window.removeEventListener('beforeunload', this.beforeUnloadListener);
	},

	beforeRouteLeave(to, from, next) {
		if (this.isUploading) {
			const allowToDiscard = confirm(this.$t('messages.uploading'));
			if (allowToDiscard) {
				next();
			}
		} else {
			next();
		}
	}
}
</script>

<style lang="scss">
.fileManager {
	&__file {
		&--selected {
			&.image-card .v-image, .plyr, &.file-card {
				outline: 2px solid #083966ce;
				outline-offset: 2px;
			}
		}
	}
	&__multiple-file {
		display: flex;
		justify-content: flex-end;
		width: 100%;
	}
}
</style>