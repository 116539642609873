import * as types from '../mutation-types'
export default {
	namespaced: true,

	state: {
		offers: []
	},

	actions: {
		fetchAll() {},
		fetchById() {},
		create() {},
		update() {},
		delete() {},
	},

	mutations: {
		[types.STORE_COUPONS](state, offers) {
			state.offers = offers;
		}
	},

	getters: {
		getOfferBySlug: state => slug => state.offers.find(c => c.slug === slug)
	}
}