import { render, staticRenderFns } from "./TextEditor.vue?vue&type=template&id=6cff9da7&scoped=true"
import script from "./TextEditor.vue?vue&type=script&lang=js"
export * from "./TextEditor.vue?vue&type=script&lang=js"
import style0 from "./TextEditor.vue?vue&type=style&index=0&id=6cff9da7&prod&lang=scss&scoped=true"
import style1 from "./TextEditor.vue?vue&type=style&index=1&id=6cff9da7&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cff9da7",
  null
  
)

export default component.exports