import * as types from '../mutation-types'
export default {
	namespaced: true,

	state: {
		coupons: []
	},

	actions: {
		fetchAll() {},
		fetchById() {},
		create() {},
		update() {},
		delete() {},
	},

	mutations: {
		[types.STORE_COUPONS](state, coupons) {
			state.coupons = coupons;
		}
	},

	getters: {
		getCouponBySlug: state => slug => state.coupons.find(c => c.slug === slug)
	}
}