import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		statics: {
			featured_product: [],
			recent_product: [],
		}
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/statics')
				.then(({ data }) => {
					commit(types.STORE_STATICS, data);
					return data;
				})
		}	
	},

	mutations: {
		[types.STORE_STATICS](state, statics) {
			state.statics = statics
		}
	}
}