import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import dashboard from './modules/dashboard'
import sets from './modules/sets'
import categories from './modules/categories'
import filters from './modules/filters'
import keywords from './modules/keywords'
import products from './modules/products'
import news from './modules/news'
import services from './modules/services'
import customServices from './modules/custom-services'
import orders from './modules/orders'
import plans from './modules/plans'
import coupons from './modules/coupons'
import offers from './modules/offers'
import albums from './modules/albums'
import videos from './modules/videos'
import sliders from './modules/sliders'
import users from './modules/users'
import systemUsers from './modules/system-users'
import branches from './modules/branches'
import subscriptions from './modules/subscriptions'
import aboutUs from './modules/about-us'
import SEO from './modules/SEO'
import legacyFileManager from './modules/legacy-file-manager'
import fileManager from './modules/file-manager'
import companyValues from './modules/company-values'
import companyHistories from './modules/company-histories'
Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		dashboard,
		sets,
		categories,
		filters,
		keywords,
		products,
		news,
		services,
		customServices,
		orders,
		plans,
		coupons,
		offers,
		albums,
		videos,
		sliders,
		users,
		systemUsers,
		branches,
		subscriptions,
		aboutUs,
		companyValues,
		companyHistories,
		legacyFileManager,
		fileManager,
		SEO
	}
})