export default {
	statistics: {
		visitors: '',
		users: '',
		'disabled-products': '',
		revenue: ''
	},

	descriptions: {
		visitors: '',
		today: '',
		'out-of-$-products': 'Out of {number} products',
		orders: ''
	},

	'products-views': '',

	'order-status': {
		title: '',
		approved: '',
		'': 'In preparation',
		shipping: '',
		delivered: '',
		unpaid: ''
	},

	'resent-added-products': '',
	'popular-products': ''
}