<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="products"
			:ids="deleteItemId"
			@on-delete="fetchProducts"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Products.the-product')}}
		</bee-delete-dialog>

		<!-- restore dialog -->
		<bee-restore-dialog
			v-model="restoreDialog"
			moduleType="products"
			:ids="restoreItemId"
			@on-restore="fetchProducts"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase orange--text text-darken-1">{{$t('restore')}}</span> 
			{{$t('Products.the-product')}}
		</bee-restore-dialog>

		<!-- confirm dialog -->
		<v-dialog
			v-model="confirmDialog"
			width="400"
			:persistent="changeStateLoading"
		>
			<v-card class="rounded-medium">
				<v-card-title class="justify-center text-uppercase text-h6">
					{{ $t('Products.confirm') + ' ' + titleState }}
				</v-card-title>
				<v-card-text class="text-body-1 pb-2">
					{{$t('Products.are-you-sure-you-want-to-$-this-user', { state: titleState })}}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="primary"
						class="rounded-small"
						:loading="changeStateLoading"
						:disabled="changeStateLoading"
						@click="changeUserState()"
					>
						{{titleState}}
					</v-btn>
					<v-btn
						class="rounded-small"
						:disabled="changeStateLoading"
						@click="confirmDialog = false; confirmId = null"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- order dialog -->
		<v-dialog
			v-model="orderDialog"
			width="300"
			:persistent="changeOrderLoading"
		>
			<v-form ref="orderFrom" @submit.prevent="changeOrder(false, orderItem)">
				<v-card class="rounded-medium">
					<v-card-title class="justify-center text-uppercase text-h6">
						{{ $t('changing-order') }}
					</v-card-title>
					<v-card-text class="text-body-1 pb-2">
						<v-text-field
							v-model.number="orderItem"
							:label="$t('inputs.order')"
							dense
							outlined
							hide-details
							class="rounded-medium"
							:rules="rules.requiredAsNumber"
						></v-text-field>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							color="primary"
							class="rounded-small"
							:loading="changeOrderLoading"
							:disabled="changeOrderLoading"
							type="submit"
						>
							{{$t('change')}}
						</v-btn>
						<v-btn
							class="rounded-small"
							:disabled="changeOrderLoading"
							@click="orderDialog = false;"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-2">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="6" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							:to="{ name: 'product' }"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Products.add-new-product')}}
						</v-btn>
					</v-col>

					<!-- search -->
					<v-col
						md="6"
						cols="12"
						class="d-flex flex-sm-row flex-column align-center text-md-start text-center"
					>
						<!-- category -->
						<v-autocomplete
							v-model="categorySlug"
							:items="categories.filter(c => !c.deletedAt)"
							item-text="name"
							item-value="id"
							:label="$t('inputs.category')"
							hide-details
							outlined
							dense
							class="rounded-medium me-sm-3 mb-sm-0 mb-5"
							:disabled="filterLoading"
						></v-autocomplete>

						<!-- name -->
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium me-sm-6 mb-sm-0 mb-5"
							:disabled="filterLoading"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
							:loading="filterLoading"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="customizedHeaders"
			:items="loading ? [] : filteredProducts"
			:loading="loading || orderLoading"
			:items-per-page="15"
			:item-class="item => dragIndex === products.indexOf(item) ? 'drag-item' : null"
			dense
			class="elevation-3 rounded-medium"
		>
			<!-- actions header -->
			<template v-slot:header.actions="{ header }">
				<div class="d-flex align-center" :class="`justify-${header.align}`">
					<span class="px-0" :class="header.class">{{header.text}}</span>
					<v-btn
						small
						icon
						@click="$eventBus.$emit(
							'headers-control',
							allHeaders.filter(c => !c.hide),
							customizedHeaders,
							(newHeaders) => {
								$eStorage.l.productsHeaders = newHeaders;
							}
						)"
					>
						<v-icon size="18">mdi-cog</v-icon>
					</v-btn>
				</div>
			</template>

			<!-- order -->
			<template v-slot:item.num="{ item }">
				{{item.num}}
			</template>
			
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.primaryImage.url"
					:height="45"
					:width="45"
					lazy-src="@/assets/blurred.png"
					transition="scale-transition"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- categories -->
			<template v-slot:item.categories="{ item }">
				{{item.categoriesIds.map(id => getCategoryById(id).name).join(', ') | isAvailable | ellipsis}}
			</template>

			<!-- summary -->
			<template v-slot:item.summary="{ item }">
				{{item.summary | isAvailable | ellipsis}}
			</template>
			
			<!-- isFeatured -->
			<template v-slot:item.isFeatured="{ item }">
				<v-icon v-if="item.isFeatured">mdi-check-circle-outline</v-icon>
				<span v-else>-</span>
			</template>

			<!-- in home -->
			<template v-slot:item.inHomePage="{ item }">
				<v-icon v-if="item.inHomePage">mdi-check-circle-outline</v-icon>
				<span v-else>-</span>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item, index }">
				<div
					class="d-flex justify-center align-center"
					@dragover.prevent
					@dragend="dragIndex = null;"
					@dragenter="dragIndex = index"
					@drop="
						orderItemId !== item.id
						? (() => {
							getDataOnDrop($event, index, products);
							changeOrder(true, index + 1);
						})()
						: null;
					"
				>
					<template v-if="!item.deletedAt">
						<template v-if="showPreview && previewUrl">
							<tooltip :text="$t('tooltip.preview-on-website')">
								<v-btn
									color="deep-purple darken-2 white--text"
									small
									class="px-1 flex-shrink-1"
									min-width="20px"
									:to="{ name: 'preview', query: { url: `${previewUrl}/${item.slug}` } }"
								><v-icon>mdi-eye</v-icon></v-btn>
							</tooltip>
							
							<tooltip :text="$t('tooltip.copy-url')">
								<v-btn
									color="blue darken-2 white--text"
									small
									class="px-1 flex-shrink-1 ms-1"
									min-width="20px"
									@click="copyToClipboard(`${previewUrl}/${item.slug}`)"
								><v-icon>mdi-link-variant</v-icon></v-btn>
							</tooltip>
							
							<v-divider vertical class="ms-1 grey darken-1"/>
						</template>
						
						<tooltip :text="$t('tooltip.edit')">
							<v-btn
								color="blue darken-4"
								dark
								small
								class="ms-1 px-1 flex-shrink-1"
								min-width="20px"
								:to="{ name: 'product', params: { slug: item.slug, id: item.id } }"
							><v-icon>mdi-pencil</v-icon></v-btn>
						</tooltip>
						
						<tooltip v-if="useOrderingItems" :text="$t('tooltip.change-order')">
							<v-btn
								color="teal darken-1"
								dark
								small
								class="ms-1 px-1"
								min-width="20px"
								@click="orderItemId = item.id; orderItem = item.num; orderDialog = true;"
							>
								<v-icon>mdi-swap-vertical</v-icon>
							</v-btn>
						</tooltip>

						<tooltip :text="$t('tooltip.delete')">
							<v-btn
								color="red darken-1"
								dark
								small
								class="ms-1 px-1"
								min-width="20px"
								@click="deleteItemId = item.id; deleteDialog = true"
							>
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</tooltip>

						<tooltip :text="$t('tooltip.active-state')">
							<v-switch
								v-model="item.isActive"
								:ripple="false"
								color="darkGreen"
								hide-details
								class="ms-2 mt-0 pt-0"
								readonly
								@click="activeConfirmDialog(item)"
							></v-switch>
						</tooltip>

						<!-- drag Icon -->
						<tooltip v-if="useOrderingItems" :text="$t('tooltip.drag-to-change-order')">
							<div
								:draggable="!orderLoading"
								@dragstart="
									orderItemId = item.id;
									setDataOnDrag($event, index);
								"
							>
								<v-icon>mdi-drag-vertical</v-icon>
							</div>
						</tooltip>
					</template>
					
					<tooltip v-else :text="$t('tooltip.restore')">
						<v-btn
							color="orange darken-1"
							dark
							small
							class="px-1"
							min-width="20px"
							@click="restoreItemId = item.id; restoreDialog = true"
						>
							<v-icon>mdi-restore</v-icon>
						</v-btn>
					</tooltip>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment'
import { products } from '@/configs/translates.config'
import { product as productPreview } from '@/configs/preview.config'
import { orders, preview } from '@/configs/routes.config';
import { useInHomePage, useOrderingItems } from '@/configs/global.config';
import { copyToClipboard, getDataOnDrop, setDataOnDrag } from '@/helpers/functions';
import rules from '@/helpers/validation rules';

export default {
	name: 'Products',

	data: () => ({
		deleteDialog: false,
		deleteItemId: null,

		restoreDialog: false,
		restoreItemId: null,

		confirmDialog: false,
		changeStateLoading: false,
		confirmId: null,

		orderDialog: false,
		changeOrderLoading: false,
		orderLoading: false,
		orderItemId: null,
		orderItem: null,

		// configs
		showPrice: orders.show,
		showPreview: preview.show,
		useInHomePage,
		useOrderingItems,

		// filter
		filterLoading: null,
		categorySlug: null,
		search: null,
		isFiltered: false,
		categoryProducts: [],

		// helpers
		titleState: null,
		loading: false,
		previewUrl: productPreview,
		dragIndex: null,

		rules,
	}),

	watch: {
		orderDialog(val) {
			if (!val) {
				this.$refs.orderFrom.reset();
				this.orderItemId = null;
			}
		},

		isFiltered(newVal) {
			if (newVal) {
				this.filter();
			} else {
				this.fetchProducts();
			}
		},
		categorySlug() {
			if (this.isFiltered) {
				this.filter();
			}
		}
	},

	computed: {
		...mapState({
			products: state => state.products.products,
			categories: state => state.categories.categories,
		}),

		...mapGetters({
			getCategoryById: 'categories/getCategoryById'
		}),

		allHeaders() {
			const headers = [
				{
					text: this.$t('headers.order'),
					value: 'num',
					align: 'center',
					width: 80,
					class: 'ps-3 px-1',
					cellClass: 'px-2',
					hide: !useOrderingItems,
					noRemove: true,
				},
				{
					text: this.$t('headers.image'),
					value: 'image',
					align: 'center',
					sortable: false
				},
				{
					text: this.$t('headers.name'),
					value: 'name',
					align: 'center',
					class: 'primary--text',
					cellClass: 'primary--text',
					noRemove: true,
				},
				{
					text: this.$t('headers.category'),
					value: 'categories',
					align: 'center',
					sortable: false
				},
				{
					text: this.$t('headers.price'),
					value: 'price',
					align: 'center',
					hide: !this.showPrice
				},
				{
					text: this.$t('headers.featured'),
					value: 'isFeatured',
					align: 'center'
				},
				{
					text: this.$t('headers.in-home'),
					value: 'inHomePage',
					align: 'center',
					hide: !useInHomePage
				},
				{
					text: this.$t('headers.summary'),
					value: 'summary',
					align: 'center',
					notInDefaultView: true,
				},
				{
					text: this.$t('headers.code'),
					value: 'code',
					align: 'center',
					hide: true,
					notInDefaultView: true,
				},
				{
					text: this.$t('headers.views'),
					value: 'view',
					align: 'center',
					notInDefaultView: true,
				},
				{
					text: this.$t('headers.date'),
					value: 'dateOfPublication',
					align: 'center',
					width: 120
				},
				{
					text: this.$t('headers.actions'),
					value: 'actions',
					align: 'center',
					width: 200,
					sortable: false,
					noRemove: true,
					fixed: true
				},
			];

			for (let i = 0; i < headers.length; i++) {
				headers[i].class = headers[i].class ? headers[i].class + ' px-2' : 'px-2'
				headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2'
			}

			return headers
		},

		customizedHeaders() {
			let headers = [];
			if (this.$eStorage.l.productsHeaders.length) {
				headers = this.$eStorage.l.productsHeaders;
			} else {
				headers = this.allHeaders.filter(c => !c.notInDefaultView);
			}
			return headers.filter(c => !c.hide);
		},

		filteredProducts() {
			const products = this.isFiltered && this.categorySlug ? this.categoryProducts : this.products;
			return this.filterByName(this.isFiltered, products, this.search)
		},
	},

	methods: {
		fetchProducts(isFirstFetch) {
			this.loading = true;
			Promise.all([
				isFirstFetch && this.categories.length === 0 ? this.$store.dispatch('categories/fetchAll') : null,
				this.$store.dispatch('products/fetchAll', {
					useOrder: useOrderingItems
				})
			]).finally(() => {
				this.loading = false;
			})
		},

		filter() {
			if (this.categorySlug) {
				this.filterLoading = true;
				this.loading = true;
				this.$store.dispatch('products/fetchAllByCategory', {
					useOrder: useOrderingItems,
					categorySlug: this.categorySlug,
				}).then((data) => {
					this.categoryProducts = data;
				}).finally(() => {
					this.filterLoading = false;
					this.loading = false;
				})
			}
		},

		activeConfirmDialog(item) {
			this.confirmId = item.id;
			this.titleState = item.isActive ? this.$t('deactivate') : this.$t('activate');
			this.confirmDialog = true;
		},

		changeUserState() {
			this.changeStateLoading = true;
			this.$store.dispatch('products/changeState', {
				id: this.confirmId
			}).then(() => {
				this.confirmDialog = false
				this.$eventBus.$emit('show-snackbar', false, this.$t('$-is-updated-successfully', { name: products.en.singular }));
				this.fetchProducts()
			}).finally(() => {
				this.changeStateLoading = false;
			})
		},

		changeOrder(useOrderLoading, order) {
			if (useOrderLoading) {
				this.orderLoading = true;
			} else {
				this.changeOrderLoading = true;
			}
			this.$store.dispatch('products/changeOrder', {
				id: this.orderItemId,
				order,
			}).then(() => {
				this.orderDialog = false;
				this.$eventBus.$emit('show-snackbar', false, this.$t('messages.item-order-is-updated'));
				if (useOrderLoading) {
					this.$store.dispatch('products/fetchAll', { useOrder: useOrderingItems });
				} else {
					this.fetchProducts();
				}
			}).finally(() => {
				this.orderLoading = false;
				this.changeOrderLoading = false;
			})
		},

		// helpers
		moment,
		copyToClipboard,
		setDataOnDrag,
		getDataOnDrop,
	},

	created() {
		this.fetchProducts(true);
	}
};
</script>

<style>
</style>
