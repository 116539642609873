<template>
	<div>
		<!-- settings dialog -->
		<v-dialog
			v-model="settingsDialog"
			max-width="350"
			content-class="rounded-medium"
		>
			<v-card>
				<v-card-title class="justify-center text-uppercase text-h6">
					{{$t('LoyaltyPoints.points-settings')}}
				</v-card-title>
				<v-card-text class="pb-2">
					<v-row dense>
						<!-- price pre loyalty point -->
						<v-col cols="12">
							<v-text-field
								v-model="loyaltyPointPrice"
								:label="$t('inputs.loyalty-point-price')"
								dense
								hide-details
								outlined
								class="rounded-medium"
							></v-text-field>
						</v-col>

						<!-- expiry duration -->
						<v-col cols="12">
							<v-text-field
								v-model="expiryDuration"
								:label="$t('inputs.expiry-duration')"
								dense
								hide-details
								outlined
								class="rounded-medium"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="justify-end">
					<v-btn color="primary" class="rounded-medium">
						{{$t('save')}}
					</v-btn>
					<v-btn class="rounded-medium" @click="settingsDialog = false">
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- filters -->
		<v-form ref="form" @submit.prevent="filter">
			<v-card class="rounded-medium mb-3">
				<v-card-text class="pa-2">
					<v-row dense class="align-center">
						<!-- change settings -->
						<v-col md="auto" cols="12" class="text-sm-start text-center">
							<v-btn
								dark
								color="darkGreen"
								class="rounded-small"
								@click="settingsDialog = true"
							>
								<v-icon class="me-1">mdi-star-cog</v-icon>
								{{$t('LoyaltyPoints.change-settings')}}
							</v-btn>
						</v-col>
			
						<v-spacer/>
			
						<!-- search -->
						<v-col
							lg="4"
							md="6"
							cols="12"
							class="d-flex flex-sm-row flex-column align-center text-md-start text-center"
						>
							<!-- category -->
							<v-autocomplete
								v-model="userId"
								:items="users"
								item-text="fullName"
								item-value="id"
								:label="$t('inputs.user')"
								hide-details
								outlined
								dense
								:disabled="filterLoading || loading"
								class="rounded-medium me-sm-3 mb-sm-0 mb-5"
								:rules="rules.requiredAsNumber"
							></v-autocomplete>
							<v-btn
								type="submit"
								color="primary"
								class="rounded-small"
								:disabled="loading"
								:loading="filterLoading"
							>
								{{isFiltered ? $t('cancel') : $t('search')}}
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-form>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="pointsLoading ? [] : loyaltyPoints"
			:loading="pointsLoading"
			:items-per-page="15"
			dense
			:no-data-text="!userId ? $t('LoyaltyPoints.please-select-user') : '$vuetify.noDataText'"
			class="elevation-3 rounded-medium"
		>
			<template v-slot:item.state="{ value }">
				<span v-if="value === loyaltyPointState.earned" class="success--text">
					<v-icon class="me-1" size="18" color="success">mdi-arrow-up</v-icon>
					{{$t('LoyaltyPoints.earned')}}
				</span>
				<span v-else-if="value === loyaltyPointState.spent" class="deep-orange--text">
					<v-icon class="me-1" size="18" color="deep-orange">mdi-arrow-down</v-icon>
					{{$t('LoyaltyPoints.spent')}}
				</span>
			</template>
		</v-data-table>

		<!-- footer -->
		<v-footer app inset color="white" class="mx-4 mb-2 py-2 rounded-medium" elevation="4">
			<v-row dense class="text-subtitle-2 text-center justify-space-around ma-0">
				<!-- points-balance -->
				<v-col md="auto" sm="4" cols="12">
					{{$t('LoyaltyPoints.points-balance')}}:
					<span class="ms-2 primary--text font-weight-bold">
						{{pointsBalance || '-'}}
					</span>
				</v-col>
				
				<!-- expire-in -->
				<v-col md="auto" sm="4" cols="12">
					{{$t('LoyaltyPoints.expire-in')}}:
					<span class="ms-2 primary--text font-weight-bold">
						{{expireIn || '-'}}
					</span>
				</v-col>
				
				<!-- total-earned-points -->
				<v-col md="auto" sm="4" cols="12">
					{{$t('LoyaltyPoints.total-earned-points')}}:
					<span class="ms-2 primary--text font-weight-bold">
						{{totalEarnedPoints || '-'}}
					</span>
				</v-col>
				
				<!-- total-spent-points -->
				<v-col md="auto" sm="6" cols="12">
					{{$t('LoyaltyPoints.total-spent-points')}}:
					<span class="ms-2 primary--text font-weight-bold">
						{{totalSpentPoints || '-'}}
					</span>
				</v-col>

				<!-- lost-points -->
				<v-col md="auto" sm="6" cols="12">
					{{$t('LoyaltyPoints.lost-points')}}:
					<span class="ms-2 primary--text font-weight-bold">
						{{lostPoints || '-'}}
					</span>
				</v-col>
			</v-row>
		</v-footer>
	</div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { loyaltyPointState } from '@/helpers/enums'
import rules from '@/helpers/validation rules'

export default {
	name: 'LoyaltyPoints',

	data() {
		return {
			loading: false,
			pointsLoading: false,
			settingsDialog: false,
			rules,

			// settings
			loyaltyPointState,
			loyaltyPointPrice: null,
			expiryDuration: null,

			// filter
			filterLoading: null,
			userId: null,
			isFiltered: false,

			// statics
			pointsBalance: 1809,
			expireIn: moment().format('YYYY-MM-DD'),
			totalEarnedPoints: 1809 + 913,
			totalSpentPoints: 913,
			lostPoints: 150,
		}
	},

	computed: {
		...mapState({
			users: state => state.users.users
		}),

		headers() {
			return [
				{
					text: this.$t('headers.points-count'),
					align: 'center',
					value: 'count',
					width: '40%'
				},
				{
					text: this.$t('headers.state'),
					value: 'state',
				},
				{
					text: this.$t('headers.date'),
					align: 'center',
					value: 'date',
					width: '40%'
				},
			]
		},

		loyaltyPoints() {
			const items = []
			for (let i = 50; i > 0; i--) {
				items.push({
					count: Math.round(Math.random() * 20),
					state: Math.round(Math.random()) ? loyaltyPointState.earned : loyaltyPointState.spent,
					date: moment().subtract(i, 'days').subtract(i * Math.random(), 'hours').format('YYYY-MM-DD, hh:mm a')
				})
			}
			return items;
		}
	},

	methods: {
		filter() {
			if (this.$refs.form.validate()) {
				this.pointsLoading = true;
				setTimeout(() => {
					this.pointsLoading = false;
				}, 1000);
			}
		}
	},

	created() {
		if (!this.users.length) {
			this.loading = true;
			this.$store.dispatch('users/fetchAll').finally(() => {
				this.loading = false;
			})
		}
	},
}
</script>

<style>

</style>