<template>
	<div
		v-ripple="!disabled && !form.disabled"
		class="bee-drop-img"
		:style="{
			width: Number(width) ? width + 'px' : width,
			height: Number(height) ? height + 'px' : height,
			borderRadius: Number(radius) ? radius + 'px' : radius,
		}"
	>
		<!-- hover -->
		<div
			class="bee-drop-img__hover"
			:class="{'bee-drop-img__hover--blurable': blurable || (clearable && value)}"
			v-if="(hoverable || blurable || clearable) && value"
		>
			<template v-if="!$slots['hover-content'] && (clearable && value)">
				<v-btn
					v-if="value && !disabled && !form.disabled"
					max-width="30px"
					max-height="30px"
					dark
					fab
					:color="clearColor"
					@click="$emit('remove-image', file); $emit('input', null); file = null; isDroped = false"
				>
					<v-icon>{{clearIcon}}</v-icon>
				</v-btn>
			</template>
			<slot v-else name="hover-content"></slot>
		</div>

		<!-- drop zone -->
		<div
			class="drop-zone"
			:class="[{ 'drop-zone--dashed': dashed }, dropZoneClasses]"
			@click="!disabled && !form.disabled ? (!disableFilePicker ? $refs.fileInput.click() : $emit('click')) : null"
			:style="{
				borderWidth: Number(borderWidth) ? borderWidth + 'px' : borderWidth,
				borderColor: borderColor,
				cursor: disabled || form.disabled ? 'default' : null,
				borderRadius: Number(radius) ? radius +'px': radius
			}"
		>
			<!-- icon & text -->
			<div v-if="!isDroped && !value" class="d-flex flex-column justify-center align-center">
				<template v-if="!$slots.defualt && !disabled && !form.disabled">
					<v-icon color="grey" large>{{icon}}</v-icon>
					<span
						v-if="icon === 'mdi-image' ? !$slots['drop-text'] : !$slots['drag-text']"
						class="text-center mt-2"
					>
						{{icon === 'mdi-image' ? dropText : dragText}}
					</span>
					<template v-else>
						<slot :name="icon === 'mdi-image' ? 'drop-text' : 'drag-text'"></slot>
					</template>
				</template>

				<template v-else>
					<slot></slot>
				</template>
			</div>

			<!-- image -->
			<div
				v-else
				class="drop-zone__show-image"
				ref="showImage"
				:style="{
					backgroundSize: contain ? 'contain' : 'cover'
				}"
			></div>

			<!-- progress -->
			<v-fade-transition>
				<v-progress-linear
					v-if="loadingImage"
					height="15"
					color="#ccc"
					:value="!stopLoading ? progressValue : 100"
					:buffer-value="stopLoading ? progressValue : 100"
					stream
					class="drop-zone__progress-line"
				/>
			</v-fade-transition>

			<v-progress-linear
				v-if="loading"
				absolute
				bottom
				indeterminate
			/>

			<!-- input -->
			<input
				type="file"
				ref="fileInput"
				accept="image/*"
				class="drop-zone__input"
				@change="setImage($refs.fileInput.files[0])"
			>

			<!-- image title -->
			<div v-if="(isDroped || (value && typeof value !== 'string')) && !hideImageName" class="drop-zone__title">
				{{file.name}}
			</div>

			<!-- drop area -->
			<div
				class="drop-zone__drop-area"
				@drop.prevent="!disabled && !form.disabled ? (() => {updateImage($event); isDroped = true})() : null"
				@dragover.prevent="icon = 'mdi-image'"
				@dragleave="icon = 'mdi-tray-arrow-down'"
			></div>
		</div>

		<!-- message -->
		<div v-if="!hideDetails" class="error__container">
			<div :class="{'error__msg--show': error}" class="error__msg text-caption error--text mb-0">
				{{error}}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BeeDropImage',
	props: {
		width: { type: [String, Number], default: '100%' },
		height: { type: [String, Number], default: '150px' },
		radius: { type: [String, Number] },
		'drag-text': { type: String, default: 'Drap and drop image here' },
		'drop-text': { type: String, default: 'Drop the image here' },
		dashed: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		'border-width': { type: [String, Number] },
		'border-color': { type: String },
		'drop-zone-classes': { type: String },
		'hide-details': { type: Boolean, default: false },
		'hide-image-name': { type: Boolean, default: false },
		'error-message': { type: String, default: 'only images are allowed' },
		hoverable: { type: Boolean, default: false },
		blurable: { type: Boolean, default: false },
		clearable: { type: Boolean, default: false },
		'clear-icon': { type: String, default: 'mdi-close' },
		'clear-color': { type: String },
		contain: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		'disable-file-picker': { type: Boolean, default: false },
		value: { type: [File, String] }
	},

	inject: {
		form: { default: {} }
	},

	data() {
		return {
			icon: 'mdi-tray-arrow-down',
			isDroped: false,
			progressValue: null,
			stopLoading: false,
			loadingImage: false,
			file: {},
			error: null,
		}
	},

	watch: {
		value(val) {
			if (!val) {
				this.$refs.fileInput.value = null;
				this.$refs.showImage.style.backgroundImage = null;
				this.file = null;
				this.isDroped = false;
				this.icon = 'mdi-tray-arrow-down';
			} else {
				if (typeof val === 'string') {
					// FIXME remove next line after fixing fetch methods
					this.setImageUrl(val);
					// BUG CORS prevent fetching some images by browser
					// this.fetchImage(val);
				} else {
					this.setImage(val)
				}
			}
		},

		progressValue(val) {
			if (val <= 60) {
				setTimeout(() => {
					this.progressValue += 29;
				}, 500)
			}
		}
	},
	
	methods: {
		updateImage(event) {
			const file = event.dataTransfer.files[0];
			this.setImage(file);
		},

		setImage(file) {
			if (/^image\/.*/g.test(file.type)) {
				this.error = null;
				this.file = file;
				this.$emit('input', file);
				this.setDropZoneImage(file);
			} else {
				this.error = this.errorMessage;
			}
		},

		setDropZoneImage(file) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.addEventListener('load', () => {
				// const uploadedImage = reader.result;
				// this.$refs.showImage.style.backgroundImage = `url(${uploadedImage})`;
				this.$refs.showImage.style.backgroundImage = `url(${URL.createObjectURL(file)})`;
			});
		},

		fetchImage(url) {
			this.loadingImage = true;
			this.progressValue = 0;
			fetch(url).then(res => res.blob())
				.then((data) => {
					const file = new File([data], 'image');
					this.file = file;
					this.setDropZoneImage(this.file);
				})
				.finally(() => {
					this.stopLoading = true;
					setTimeout(() => {
						this.loadingImage = false;
					}, 1000)
				});
		},

		// FIXME remove setImageUrl after fixing fetch methods
		setImageUrl(val) {
			this.$nextTick(() => {
				if (this.$refs.showImage) {
					this.$refs.showImage.style.backgroundImage = `url(${val})`;
				}
			})
		},
	},

	created() {
		if (this.value) {
			if (typeof this.value === 'string') {
				// FIXME remove setImageUrl after fixing fetch methods
				this.setImageUrl(this.value)
				// BUG CORS prevent fetching some images by browser
				// this.fetchImage(this.value);
			} else {
				this.setDropZoneImage(this.value)
			}
		}
	}
}
</script>

<style lang="scss">
$color-light-gray: #eee;
.bee-drop-img {
	position: relative;
	overflow: hidden;

	&__hover {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;

		display: flex;
		justify-content: center;
		align-items: center;
		
		opacity: 0;
		background-color: #3333;
		transition: all ease .3s;
		&:hover {
			opacity: 1;
		}

		&--blurable {
			z-index: 5;
			&:hover + .drop-zone .drop-zone__show-image {
				filter: blur(1px);
			}
		}
	}

	.drop-zone{
		position: relative;
		z-index: 3;
		border: 1px solid $color-light-gray;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		cursor: pointer;
		width: 100%;
		height: 100%;

		&--dashed{
			border-style: dashed;
		}

		&__input{
			display: none;
		}

		&__show-image {
			width: 100%;
			height: 100%;

			background-position: center;
		}

		&__progress-line {
			position: absolute;
			width: 70%;
			top: 40%;
			left: 15%;
			right: 15%;
			border-radius: 20px;
		}

		&__title {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 3px 0;
			text-align: center;
			color: white;
			background-color: #3338;
		}

		&__drop-area {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 30;

			width: 100%;
			height: 100%;
		}
	}
	.error {
		&__container {
			height: 20px;
		}

		&__msg {
			transform: translateY(-10px);
			transition: transform .4s;
			
			&--show {
				transform: translateY(0);
			}
		}
	}
}
</style>