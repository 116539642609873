import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import midad, { $eventBus } from './plugins/midad'
import i18n from './i18n/i18n'
import axios from 'axios'
import moment from 'moment/moment'
import { BASE_URL } from './helpers/constants'
import { login } from './configs/routes.config'
import { defaultLang, multiLangUI } from './configs/translates.config'
import { eStorage as es } from '@beetronix/estorage'
import { eStorage } from './plugins/eStorage'

Vue.use(VueMeta)

Vue.config.productionTip = false;

window.axios = axios.create({
	baseURL: BASE_URL + '/api',
	headers: { common: { 'accept-language': multiLangUI ? i18n.locale : defaultLang } }
})
window.axios.interceptors.response.use(
	function (response) {
		/*
			TODO
			return response.data instead of response
			to remove (.then(({ data }) => data)) from store modules
		*/
		return response;
	},
	
	function (error) {
		if (!error.response || !window.navigator.onLine) {
			$eventBus.$emit('show-snackbar', true, i18n.t('messages.check-your-connection'))
		}
		if (error.response && error.response.status === 401) {
			store.dispatch('auth/logout');
			router.replace({ name: login.name })
			$eventBus.$emit('show-snackbar', false, i18n.t('messages.session-is-end'))
		}
		if (router.currentRoute.name !== login.name) {
			$eventBus.$emit('show-snackbar', true, error.response.data.message || error.response.data.error)
		}
		return Promise.reject(error);
	}
);

if (es.l.has('auth')) {
	const remainingMinutes = moment.duration(moment(es.l.get('expiry-date'))).minutes();
	if (remainingMinutes < 0) {
		store.dispatch('auth/logout');
	} else {
		store.state.auth.authenticated = true;
		const auth = es.l.get('auth')
		window.axios.defaults.headers.common.authorization = `Bearer ${auth.token}`;
		store.state.auth.self.id = auth.id;
		store.state.auth.self.name = auth.name;
		store.state.auth.self.expiresIn = auth.expiresIn;
		store.state.auth.self.hasPermission = auth.hasPermission;
	}
}
new Vue({
	router,
	store,
	vuetify,
	eStorage,
	midad,
	i18n,
	render: h => h(App)
}).$mount('#app')

/* const Mailgun = require('mailgun.js').default;
const mailgun = new Mailgun(FormData);
const mg = mailgun.client({ username: 'api', key: 'pubkey-22da3390007784113431b41ba798c895' });
window.mg = mg; */

/* mg.messages.create('sandbox-123.mailgun.org', {
	from: 'Excited User <mailgun@sandbox-123.mailgun.org>',
	to: ['test@example.com'],
	subject: 'Hello',
	text: 'Testing some Mailgun awesomeness!',
	html: '<h1>Testing some Mailgun awesomeness!</h1>'
})
.then(msg => console.log(msg)) // logs response data
.catch(err => console.log(err)); // logs any error */

// https://api.mailgun.net/v3/sandbox77a8b565919b4c64bc5d7d69483b6943.mailgun.org
// DOMAIN: sandbox77a8b565919b4c64bc5d7d69483b6943.mailgun.org
// API KEY: 5880c4c87a5fbafb1681686937719738-8c8e5529-45801b61
// public KEY: pubkey-22da3390007784113431b41ba798c895
// private KEY: 2b1788c66a32117799b6ff5f1db9a330-8c8e5529-d9c9557d

// ***** Mailgun DOCS (403 because sandbox77a8b565919b4c64bc5d7d69483b6943.mailgun.org just for testing) *****
/*
const Mailgun = require('mailgun.js').default;
const mailgun = new Mailgun(FormData);
const ms = mailgun.client({
	key: '5880c4c87a5fbafb1681686937719738-8c8e5529-45801b61',
	username: 'api',
	public_key: 'pubkey-22da3390007784113431b41ba798c895'
})
window.mailgun = ms;
*/

// ***** AXIOS (worked) *****
/*
axios({
    method: 'post',
    url: `https://api.mailgun.net/v3/sandbox77a8b565919b4c64bc5d7d69483b6943.mailgun.org/messages`,
    auth: {
        username: 'api',
        password: '5880c4c87a5fbafb1681686937719738-8c8e5529-45801b61'
    },
    params: {
        from: 'tester <tester@merepost.com>',
        to: 'netrolirzu@gufum.com',
        subject: 'Hello',
        text: 'Welcome to the team!',
		'o:deliverytime': 'Thu, 13 Feb 2024 19:02:00 +0000'
    }
}).then(
    response => console.log(response),
    reject => console.log(reject)
)
*/

// ***** CHAT GPT WAY *****
/* ms.messages.create('sandbox77a8b565919b4c64bc5d7d69483b6943.mailgun.org', {
	from: 'Excited User <mailgun@sandbox-123.mailgun.org>',
	to: ['test@example.com'],
	subject: 'Hello',
	text: 'Testing some Mailgun awesomeness!',
	html: '<h1>Testing some Mailgun awesomeness!</h1>'
}) */

/* const data = {
	from: 'Excited User <mailgun@sandbox77a8b565919b4c64bc5d7d69483b6943.mailgun.org>',
	to: 'vemlijudro@gufum.com',
	subject: 'Hello from Mailgun',
	text: 'Testing some Mailgun awesomeness!'
};

mailgun.messages().send(data, (error, body) => { console.log(body); console.log(error); }); */

// **elasticemail**
// API KEY: BAC37FCDBA4217467B45C748CD4AC53E8FAAF484AE908B20FAF683B41C074DA878DE9603480418EEE056C648530BFEEA
/* window.elastic = axios.create({
	baseURL: 'https://api.elasticemail.com/v4',
	headers: { common: { 'X-ElasticEmail-ApiKey': 'BAC37FCDBA4217467B45C748CD4AC53E8FAAF484AE908B20FAF683B41C074DA878DE9603480418EEE056C648530BFEEA' } }
}); */
// const defaultClient = ElasticEmail.ApiClient.instance;
// // Configure API key authorization: apikey
// const apikey = defaultClient.authentications['apikey'];
// apikey.apiKey = 'YOUR API KEY'

// Elastic
/* send mail
window.elastic.post('/emails', {
	Recipients: [
		{ Email: 'sertugidra@gufum.com' },
		{ Email: 'cababot246@seosnaps.com' },
	],
	Content: {
		Body: [
			{
				ContentType: 'HTML',
				Content: `<p>Hi dear,<br />You are the <strong>BEST </strong><strong>USER</strong> so you will get this Coupon</p>
				<p style='text-align: center;'><span style='font-size: 18pt;'><strong><span style='background-color: #2dc26b; color: #ecf0f1; border-radius: 6px; padding: 10px 60px;'>BestUser-UAE-55</span></strong></span></p>
				<p style='text-align: left;'><span style='font-size: 12pt;'>check the <a href='https://beetronix.com'>link</a> to use it</span></p>`,
				Charset: 'utf-8'
			},
		],
		EnvelopeFrom: 'John Doe <email@domain.com>',
		From: 'Tester Mo <tester@merepost.com>',
		ReplyTo: 'Tester Mo <tester@merepost.com>',
		Subject: 'YOU GOT a discount COUPON',
	},
	Options: {
		TimeOffset: 10,
		PoolName: 'My Custom Pool',
		ChannelName: 'Channel01',
		Encoding: 'UserProvided',
	}
})
*/
// 6318085a-f2c5-41f0-a05a-5d5261f7975d
/* window.elastic.post('/contacts', [
	{
		Email: 'contact@bacot.in',
		Status: 'Transactional',
		FirstName: 'Contact',
		LastName: 'bacot',
		CustomFields: { city: 'New York', age: '34' },
	}
]) */