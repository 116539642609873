import { useRestore } from '@/configs/global.config';
import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		keywords: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/keywords').then(({ data }) => {
				const storeData = data.filter(c => !useRestore.keywords ? !c.deletedAt : true);
				commit(types.STORE_KEYWORDS, storeData);
				return storeData;
			})
		},
		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/keywords/${slug}`, configs).then(({ data }) => data);
		},
		create({ commit }, { name, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.post('/keywords', { name }, configs).then(({ data }) => data)
		},
		update({ commit }, { id, name, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.post(`/keywords/${id}`, { name, _method: 'PUT' }, configs).then(({ data }) => data)
		},
		delete({ commit }, { id }) {
			return axios.delete(`/keywords/${id}`)
		},
		restore({ commit }, { id }) {
			return axios.patch(`/keywords/${id}`)
		},
	},

	mutations: {
		[types.STORE_KEYWORDS](state, keywords) {
			state.keywords = keywords;
		}
	},

	getters: {
		getKeywordById: state => id => state.keywords.find(c => c.id === id),
	}
}