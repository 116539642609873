<template>
	<div>
		<bee-delete-dialog
			v-if="false"
			v-model="deleteDialog"
			moduleType=''
			:ids="deleteItemId"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('SystemUsers.the-system-user')}}
		</bee-delete-dialog>

		<!-- add/edit dialog -->
		<v-dialog
			v-model="dialog"
			width="600"
			:persistent="submitLoading"
		>
			<v-card class="rounded-medium">
				<v-form ref="form" @submit.prevent="submit" :disabled="submitLoading">
					<v-card-title class="justify-center text-uppercase text-h6">
						{{ editItemId ? $t('edit') : $t('add')}} {{$t('SystemUsers.users')}}
					</v-card-title>
					<v-card-text class="pb-0">
						<v-row dense>
							<!-- name -->
							<v-col sm="6" cols="12">
								<v-text-field
									v-model="userData.fullName"
									:label="$t('inputs.name')"
									outlined
									dense
									class="rounded-small"
									hide-details="auto"
									:rules="rules.required"
								></v-text-field>
							</v-col>

							<!-- set -->
							<v-col sm="6" cols="12">
								<v-autocomplete
									v-model="userData.setId"
									:label="$t('inputs.set')"
									:items="[
										{ id: null, name: 'All' },
										...sets
									]"
									hide-details="auto"
									item-text="name"
									item-value="id"
									outlined
									dense
									class="rounded-small"
								></v-autocomplete>
							</v-col>

							<!-- username -->
							<v-col sm="4" cols="12">
								<v-text-field
									v-model="userData.username"
									:label="$t('inputs.username')"
									outlined
									dense
									hide-details="auto"
									class="rounded-small"
									:rules="rules.required"
								></v-text-field>
							</v-col>
					
							<!-- password -->
							<v-col sm="4" cols="12">
								<v-text-field
									v-model="userData.password"
									:label="$t('inputs.password')"
									type="password"
									outlined
									dense
									hide-details="auto"
									class="rounded-small"
									:hint="editItemId ? $t('SystemUsers.new-value-replace-old') : null"
									:persistent-hint="Boolean(editItemId)"
									:rules="!editItemId ? rules.required : []"
								></v-text-field>
							</v-col>
					
							<!-- confirm-password -->
							<v-col sm="4" cols="12">
								<v-text-field
									v-model="userData.passwordConfirm"
									:label="$t('inputs.confirm-password')"
									type="password"
									outlined
									dense
									hide-details="auto"
									class="rounded-small"
									:rules="[(val) => val === userData.password || $t('SystemUsers.not-matched')]"
								></v-text-field>
							</v-col>
							
							<!-- email -->
							<v-col sm="6" cols="12">
								<v-text-field
									v-model="userData.email"
									:label="$t('inputs.email')"
									outlined
									dense
									hide-details="auto"
									class="rounded-small"
									:rules="rules.required"
								></v-text-field>
							</v-col>
							
							<!-- address -->
							<v-col sm="6" cols="12">
								<v-text-field
									v-model="userData.address"
									:label="$t('inputs.address')"
									outlined
									dense
									hide-details="auto"
									class="rounded-small"
								></v-text-field>
							</v-col>
							
							<!-- phone -->
							<v-col sm="6" cols="12">
								<v-text-field
									v-model="userData.phone"
									:label="$t('inputs.phone')"
									outlined
									dense
									hide-details="auto"
									class="rounded-small"
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							class="rounded-small"
							:loading="submitLoading"
						>{{$t('save')}}</v-btn>
						<v-btn @click="dialog = false" class="rounded-small" :disabled="submitLoading">
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- confirm dialog -->
		<v-dialog
			v-model="confirmDialog"
			width="400"
			:persistent="changeStateLoading"
		>
			<v-card class="rounded-medium">
				<v-card-title class="justify-center text-uppercase text-h6">
					{{ $t('confirm') + ' ' + titleState }}
				</v-card-title>
				<v-card-text class="text-body-1 pb-2">
					{{$t('SystemUsers.are-you-sure-you-want-to-$-this-user', { state: titleState })}}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="primary"
						class="rounded-small"
						:loading="changeStateLoading"
						:disabled="changeStateLoading"
						@click="changeUserState()"
					>
						{{titleState}}
					</v-btn>
					<v-btn
						class="rounded-small"
						:disabled="changeStateLoading"
						@click="confirmDialog = false; confirmId = null"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text v-if="true" class="pa-2">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('SystemUsers.add-new-system-user')}}
						</v-btn>
					</v-col>

					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							:label="$t('search')"
							hide-details="auto"
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
						>
							{{$t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>

			<v-card-text v-else class="pa-3">
				<v-row class="justify-center align-center">
					<v-col lg="3" md="4" sm="6" cols="12">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details="auto"
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium"
						></v-text-field>
					</v-col>
					<v-spacer/>
					<v-col cols="auto">
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="customizedHeaders"
			:items="loading ? [] : filteredUsers"
			:loading="loading"
			dense
			:items-per-page="15"
			disable-sort
			class="elevation-3 rounded-medium"
		>
			<!-- actions header -->
			<template v-slot:header.actions="{ header }">
				<div class="d-flex align-center" :class="`justify-${header.align}`">
					<span class="px-0" :class="header.class">{{header.text}}</span>
					<v-btn
						small
						icon
						@click="$eventBus.$emit(
							'headers-control',
							allHeaders.filter(c => !c.hide),
							customizedHeaders,
							(newHeaders) => {
								$eStorage.l.systemUsersHeaders = newHeaders;
							}
						)"
					>
						<v-icon size="18">mdi-cog</v-icon>
					</v-btn>
				</div>
			</template>

			<!-- address -->
			<template v-slot:item.address="{ item }">
				{{ item.address | isAvailable }}
			</template>

			<!-- email -->
			<template v-slot:item.email="{ item }">
				{{ item.email | isAvailable }}
			</template>

			<!-- phone -->
			<template v-slot:item.phone="{ item }">
				{{ item.phone | isAvailable }}
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-center align-center">
					<tooltip :text="$t('tooltip.edit')">
						<v-btn
							color="blue darken-4"
							dark
							small
							class="me-1 px-1 flex-shrink-1"
							min-width="20px"
							@click="editItemId = item.id; dialog = true;"
						><v-icon>mdi-pencil</v-icon></v-btn>
					</tooltip>

					<v-btn
						v-if="false"
						color="red darken-1"
						dark
						small
						class="px-1"
						min-width="20px"
						@click="deleteItemId = item.id; deleteDialog = true"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>

					<tooltip :text="$t('tooltip.active-state')">
						<v-switch
							v-model="item.isBanned"
							:ripple="false"
							:true-value="0"
							:false-value="1"
							color="darkGreen"
							hide-details="auto"
							readonly
							@click="activeConfirmDialog(item)"
							class="ms-2 mt-0 pt-0"
						></v-switch>
					</tooltip>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex';

export default {
	name: 'SystemUsers',

	data: () => ({
		dialog: false,		
		editItemId: null,
		
		deleteDialog: false,
		deleteItemId: null,
		
		confirmDialog: false,
		confirmId: null,
		titleState: null,
		changeStateLoading: false,

		// filter
		search: null,
		isFiltered: null,

		// helpers
		loading: false,
		submitLoading: false,
		rules,

		// data
		userData: {
			name: null,
			username: null,
			email: null,
			address: null,
			phone: null,
			password: null,
			passwordConfirm: null,
			setId: null
		}
	}),

	watch: {
		dialog(newVal) {
			if (newVal) {
				if (this.editItemId) {
					const user = this.getSystemUserById(this.editItemId);

					this.userData.fullName = user.fullName;
					this.userData.username = user.userName;
					this.userData.email = user.email;
					this.userData.address = user.address;
					this.userData.phone = user.phone;
					this.userData.setId = user.set?.id ?? null;
				}
			} else {
				this.$refs.form.reset();
				this.editItemId = null;
			}
		}
	},

	computed: {
		...mapState({
			self: state => state.auth.self,
			systemUsers: state => state.systemUsers.systemUsers,
			sets: state => state.sets.sets
		}),

		...mapGetters({
			getSystemUserById: 'systemUsers/getSystemUserById'
		}),

		allHeaders() {
			return [
				{
					text: this.$t('headers.name'),
					value: 'fullName',
					align: 'center',
					class: 'primary--text',
					cellClass: 'primary--text',
					noRemove: true
				},
				{
					text: this.$t('headers.username'),
					value: 'userName',
					align: 'center'
				},
				{
					text: this.$t('headers.address'),
					value: 'address',
					align: 'center'
				},
				{
					text: this.$t('headers.email'),
					value: 'email',
					align: 'center'
				},
				{
					text: this.$t('headers.phone'),
					value: 'phone',
					align: 'center'
				},
				{
					text: this.$t('headers.actions'),
					value: 'actions',
					align: 'center',
					noRemove: true,
					fixed: true
				},
			]
		},

		customizedHeaders() {
			let headers = [];
			if (this.$eStorage.l.systemUsersHeaders.length) {
				headers = this.$eStorage.l.systemUsersHeaders;
			} else {
				headers = this.allHeaders.filter(c => !c.notInDefaultView);
			}
			return headers.filter(c => !c.hide);
		},

		filteredUsers() {
			return this.filterByName(this.isFiltered, this.systemUsers, this.search, 'fullName')
				.filter(c => c.isAdmin && c.userName !== 'admin' && c.id !== this.self.id);
		}
	},

	methods: {
		activeConfirmDialog(item) {
			this.confirmId = item.id;
			this.titleState = item.is_banned ? this.$t('activate') : this.$t('deactivate');
			this.confirmDialog = true;
		},

		changeUserState() {
			this.changeStateLoading = true;
			this.$store.dispatch('systemUsers/changeState', {
				id: this.confirmId
			}).then(() => {
				this.confirmDialog = false
				this.$eventBus.$emit('show-snackbar', false, this.$t('SystemUsers.user-state-is-changed-successfully'));
				this.fetchUsers();
			}).finally(() => {
				this.changeStateLoading = false;
			})
		},

		submit() {
			if (this.$refs.form.validate()) {
				const actionName = this.editItemId ? 'update' : 'create';

				this.submitLoading = true;
				this.$store.dispatch(`systemUsers/${actionName}`, {
					id: this.editItemId,
					fullName: this.userData.fullName,
					userName: this.userData.username,
					email: this.userData.email,
					address: this.userData.address,
					phone: this.userData.phone,
					password: this.userData.password,
					passwordConfirmation: this.userData.passwordConfirm,
					setId: this.userData.setId
				}).then(() => {
					const message =
						this.editItemId
							? this.$t('$-is-updated-successfully', { name: this.$t('SystemUsers.system-user') })
							: this.$t('$-is-created-successfully', { name: this.$t('SystemUsers.system-user') });
					this.$eventBus.$emit('show-snackbar', false, message);
					
					this.dialog = false;
					this.fetchUsers();
				}).finally(() => {
					this.submitLoading = false;
				})
			}
		},

		fetchUsers(isFirstLoad = false) {
			this.loading = true;
			return this.$store.dispatch('systemUsers/fetchAll')
				.finally(() => {
					if (!isFirstLoad) this.loading = false;
				})
		}
	},

	created() {
		this.loading = true;
		Promise.all([
			!this.sets.length ? this.$store.dispatch('sets/fetchAll') : null,
			this.fetchUsers()
		]).finally(() => {
			this.loading = false;
		})
	}
};
</script>

<style>
</style>
