<template>
	<v-apex-chart
		ref="apexchart"
		dir="ltr"
		:type="type"
		:height="height"
		:options="chartOptions"
		:series="series"
	/>
</template>

<script>
import VueApexChart from 'vue-apexcharts'
export default {
	name: 'ApexChart',
	props: {
		year: { type: String, default: new Date().toISOString().slice(0, 4) },
		type: { type: String, default: 'line' },
		height: { type: [String, Number], default: 350 },
		series: { type: Array },
		labels: { type: Array, default: () => [] },
		'distributed-labels': { type: Boolean, default: false },
		'show-tooltip': { type: Boolean, default: false },
		'trim-labels': { type: Boolean, default: false },
		'max-columns-count': { type: Number, default: 10 },
		'data-labels-offset-y': { type: Number },
		'enable-data-labels-background': { type: Boolean, default: false },
		datetime: { type: Boolean, default: false },
		colors: { type: Array, default: () => [] },
	},
	components: {
		VApexChart: VueApexChart
	},

	computed: {
		chartOptions() {
			const minDate = `${this.year}-01-01`;
			const maxDate = `${this.year}-12-31`;
			const colors =
				!this.colors.length
					? [this.$vuetify.theme.themes.light.primary, this.$vuetify.theme.themes.light.success]
					: this.colors;

			const isDatetime = this.datetime;
			const options = {
				annotations: isDatetime
					? {
						xaxis: [{
							x: new Date().getTime(),
							borderColor: '#3c4491',
							strokeDashArray: [1, 0, 1],
							yAxisIndex: 0,
							label: { show: true, text: 'Today', orientation: 'horizontal', style: { color: '#fff', background: '#3c4491' } }
						}]
					}
					: {},
				type: isDatetime ? 'datetime' : '',
				min: isDatetime ? new Date(minDate).getTime() : 1,
				max: isDatetime ? new Date(maxDate).getTime() : this.maxColumnsCount,
				tickPlacement: isDatetime ? 'between' : 'on',
				offsetY: this.dataLabelsOffsetY ? this.dataLabelsOffsetY : (isDatetime ? -8 : -20),
				dataLabelsStyle: isDatetime
					? {
						colors: [({ series, dataPointIndex }) => series[0][dataPointIndex] < 0 ? '#e00c23' : '#02855b']
					}
					: { colors: colors }
			}
			
			return {
				chart: {
					type: this.type,
					toolbar: {
						show: true,
						tools: {
							download: false,
							pan: !isDatetime,
							/* reset: '<span class="mdi mdi-chart-timeline" style="font-size: 20px"></span>',
							customIcons: [{	
								icon: '<span class="mdi mdi-home" style="font-size: 20px"></span>',
								index: -4,
								title: 'home',
								click: () => {
									this.$refs.apexchart.chart.zoomX(
										new Date(`${this.chartYear}-01-01`).getTime(),
										new Date(`${this.chartYear}-12-31`).getTime()
									)
								}
							}] */
						},
					},
					zoom: { enabled: true }
				},
				// colors: this.colors, 
				tooltip: { enabled: this.showTooltip },
				dataLabels: {
					enabled: true,
					formatter: (val) => val.toLocaleString(),
					background: { enabled: this.enableDataLabelsBackground, borderRadius: 7, padding: 5 },
					offsetY: options.offsetY,
					style: options.dataLabelsStyle,
				},
				colors: colors,
				annotations: options.annotations,
				xaxis: {
					type: options.type,
					min: options.min,
					max: options.max,
					tickAmount: this.maxColumnsCount,
					tickPlacement: options.tickPlacement,
					labels: {
						datetimeFormatter: { year: 'yyyy', month: 'MMM yyyy', day: 'dd' },
						trim: this.trimLabels,
						style: { colors: this.colors.length ? this.colors : [] }
					},
					categories: this.labels
				},
				plotOptions: {
					bar: {
						align: 'start',
						borderRadius: 5,
						dataLabels: { position: 'top' },
						distributed: this.distributedLabels,
					}
				},
				legend: { show: !this.distributedLabels },
				noData: { text: 'No data' },
				stroke: { curve: 'straight' },
				markers: { size: 4 },
			}
		},
	}
}
</script>

<style>

</style>