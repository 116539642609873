<template>
	<div>
		<p class="text-h6 text-capitalize primary--text mb-0">{{$t('UserInfo.basic-info')}}</p>
		<v-row align="center" class="ma-0 mb-5">
			<!-- fullname -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-text-field
					:label="$t('UserInfo.fullname')"
					hide-details
					outlined
					dense
					prepend-icon="mdi-account"
					persistent-placeholder
					readonly
					:value="fullname"
					:loading="loading"
					class="rounded-medium"
				></v-text-field>
			</v-col>
		
			<!-- username -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-text-field
					:label="$t('UserInfo.username')"
					hide-details
					outlined
					dense
					prepend-icon="mdi-badge-account-outline"
					persistent-placeholder
					readonly
					:value="username"
					:loading="loading"
					class="rounded-medium"
				></v-text-field>
			</v-col>
		
			<!-- email -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-text-field
					:label="$t('UserInfo.email')"
					hide-details
					outlined
					dense
					prepend-icon="mdi-email-outline"
					persistent-placeholder
					readonly
					:value="email"
					:loading="loading"
					class="rounded-medium"
				></v-text-field>
			</v-col>
		
			<!-- address -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-text-field
					:label="$t('UserInfo.address')"
					hide-details
					outlined
					dense
					prepend-icon="mdi-map-marker-outline"
					persistent-placeholder
					readonly
					:value="address"
					:loading="loading"
					class="rounded-medium"
				></v-text-field>
			</v-col>
		
			<!-- phone -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-text-field
					:label="$t('UserInfo.phone')"
					hide-details
					outlined
					dense
					prepend-icon="mdi-phone"
					persistent-placeholder
					readonly
					:value="phone"
					:loading="loading"
					class="rounded-medium"
				></v-text-field>
			</v-col>
		
			<!-- banned -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-switch
					v-model="isBanned"
					:label="isBanned ? $t('UserInfo.inactive') : $t('UserInfo.active')"
					:true-value="0"
					:false-value="1"
					:ripple="false"
					readonly
					hide-details
					color="darkGreen"
					class="ma-0"
				>
					<template v-slot:prepend>
						<v-icon v-if="isBanned" size="25" color="error lighten-2">
							mdi-account-cancel
						</v-icon>
						<v-icon v-else size="25" color="darkGreen">
							mdi-account-check
						</v-icon>
					</template>
				</v-switch>
			</v-col>
		
			<!-- user type -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-text-field
					:label="$t('UserInfo.user-type')"
					hide-details
					outlined
					dense
					prepend-icon="mdi-account-tag-outline"
					persistent-placeholder
					readonly
					:value="getUserType()"
					:loading="loading"
					class="rounded-medium"
				></v-text-field>
			</v-col>
		
			<!-- user country -->
			<v-col xl="2" md="4" sm="6" cols="12" class="text-body-1">
				<v-text-field
					:label="$t('UserInfo.user-country')"
					hide-details
					outlined
					dense
					prepend-icon="mdi-city-variant"
					persistent-placeholder
					readonly
					:value="userData.country"
					:loading="loading"
					class="rounded-medium"
				></v-text-field>
			</v-col>
		</v-row>

		<!-- additional info -->
		<template v-if="showAdditionalInfo">
			<p class="text-h6 text-capitalize primary--text mb-0">{{$t('UserInfo.additional-info')}}</p>
			<v-row class="ma-0 mb-5">
				<v-col xl="2" lg="3" md="4" sm="6" cols="12" v-if="additionalUserInfo.latestWebsiteVisit">
					<v-text-field
						:label="$t('UserInfo.latest-visit')"
						hide-details
						outlined
						dense
						prepend-icon="mdi-web-clock"
						persistent-placeholder
						readonly
						:loading="loading"
						class="rounded-medium"
						:value="additionalInfo.latestVisit"
					></v-text-field>
				</v-col>
			</v-row>
		</template>
		
		<!-- user plan -->
		<p class="text-h6 text-capitalize primary--text mb-0">{{$t('UserInfo.user-plan')}}</p>
		<v-row class="ma-0 mb-5">
			<template v-if="loading || hasPlan">
				<!-- plan name -->
				<v-col xl="2" lg="3" md="4" sm="6" cols="12" class="text-body-1">
					<v-text-field
						v-model="planData.name"
						:label="$t('UserInfo.plan-name')"
						hide-details
						outlined
						dense
						prepend-icon="mdi-card-bulleted-outline"
						persistent-placeholder
						readonly
						:value="userData.name"
						:loading="loading"
						class="rounded-medium"
					></v-text-field>
				</v-col>

				<!-- plan date -->
				<v-col xl="2" lg="3" md="4" sm="6" cols="12" class="text-body-1">
					<v-text-field
						v-model="planData.date"
						:label="$t('UserInfo.subscription-in-plan-date')"
						hide-details
						outlined
						dense
						prepend-icon="mdi-calendar"
						persistent-placeholder
						readonly
						:value="userData.date"
						:loading="loading"
						class="rounded-medium"
					></v-text-field>
				</v-col>

				<!-- subscription end in -->
				<v-col xl="2" lg="3" md="4" sm="6" cols="12" class="text-body-1">
					<v-text-field
						:label="$t('UserInfo.subscription-end-in')"
						hide-details
						outlined
						dense
						prepend-icon="mdi-calendar-clock"
						persistent-placeholder
						readonly
						:value="planData.date ? moment(planData.date).add(planData.duration, 'months').format('YYYY-MM-DD') : null"
						:loading="loading"
						class="rounded-medium"
					></v-text-field>
				</v-col>
		
				<!-- plan price -->
				<v-col xl="2" lg="3" md="4" sm="6" cols="12" class="text-body-1">
					<v-text-field
						v-model="planData.price"
						:label="$t('UserInfo.plan-price')"
						hide-details
						outlined
						dense
						prepend-icon="mdi-cash"
						persistent-placeholder
						readonly
						:loading="loading"
						class="rounded-medium"
					></v-text-field>
				</v-col>
			</template>
		
			<v-col v-else md="4" sm="6" cols="12" class="text-body-1">
				<div class="d-flex align-center text-body-1 text-uppercase font-weight-medium me-2">
					<v-icon size="22" color="grey darken-2" class="me-2">mdi-information-outline</v-icon>
					{{$t('UserInfo.user-has-no-plan')}}
				</div>
			</v-col>
		</v-row>

		<!-- statistics -->
		<div v-if="showAOV || showCartAbandonedRate" class="mb-5">
			<p class="text-h6 text-capitalize primary--text mb-0">
				{{$t('statistics.title')}}
			</p>

			<v-row dense align="center" class="ma-0">
				<v-col cols="auto">
					<v-chip-group
						v-model="chartType"
						mandatory
						dense
						show-arrows
						active-class="primary"
					>
						<v-chip v-if="showAOV" small>
							{{$t('statistics.average-order-value')}}
						</v-chip>
						<v-chip v-if="showCartAbandonedRate" small>
							{{$t('statistics.cart-abandoned-rate')}}
						</v-chip>
					</v-chip-group>
				</v-col>
				
				<v-spacer/>

				<!-- year -->
				<v-col md="2" sm="3" cols="12" class="d-flex align-center" dir="ltr">
					<v-btn
						icon
						@click="chartYear = moment(chartYear).subtract(1, 'years').format('YYYY')"
					>
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-text-field
						v-model="chartYear"
						dir="ltr"
						dense
						outlined
						hide-details
						readonly
						class="year-input rounded-medium"
					></v-text-field>
					<v-btn
						icon
						:disabled="chartYear === moment().format('YYYY')"
						@click="chartYear = moment(chartYear).add(1, 'years').format('YYYY')"
					>
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-col>
			</v-row>

			<!-- chart -->
			<div class="ma-0 mb-5">
				<v-card class="rounded-medium">
					<v-card-text class="px-0 py-2">
						<apex-chart
							type="line"
							show-tooltip
							datetime
							:year="chartYear"
							:series="chartSeries"
						/>
					</v-card-text>
				</v-card>
			</div>
		</div>

		<v-btn
			color="white"
			class="rounded-medium"
			:to="{ name: 'users' }"
		>
			{{$t('back')}}
		</v-btn>
	</div>
</template>

<script>
import moment from 'moment';
import { additionalUserInfo, statistics } from '@/configs/global.config';

export default {
	name: 'UserInfo',

	data: () => ({
		loading: false,

		// configs
		additionalUserInfo,
		showAdditionalInfo: Object.keys(additionalUserInfo).some(key => additionalUserInfo[key]),
		showAOV: statistics.averageOrderValue,
		showCartAbandonedRate: statistics.cartAbandonedRate,

		// data
		fullname: null,
		username: null,
		email: null,
		address: null,
		phone: null,
		isBanned: false,

		userData: {
			type: null,
			country: null,
		},

		additionalInfo: {
			latestVisit: null,
		},
		
		hasPlan: false,
		planData: {
			name: null,
			date: null,
			price: null,
			duration: null,
		},

		chartType: 0,
		chartYear: moment().format('YYYY')
	}),

	computed: {
		chartSeries() {
			const series = [];
			if (this.chartType === 0) {
				series.push({
					name: this.$t('statistics.average-order-value'),
					data: [
						['2023-02-01', 120],
						['2023-09-01', 400],
						['2023-11-01', 300],
						['2023-12-04', 120],
						['2024-01-08', 20],
					]
				})
			} else if (this.chartType === 1) {
				series.push({
					name: this.$t('statistics.cart-abandoned-rate'),
					data: [
						['2023-08-01', 20],
						['2023-10-01', 100],
						['2023-11-01', 20],
						['2023-12-04', 80],
						['2024-02-02', 2],
					]
				})
			}
			return series;
		},
	},

	methods: {
		fetchUserInfo() {
			this.loading = true;
			this.$store.dispatch('users/fetchById', {
				id: this.$route.params.id
			}).then((data) => {
				this.fullname = data.fullname;
				this.username = data.username;
				this.email = data.email;
				this.address = data.address;
				this.phone = data.phone;
				this.isBanned = data.isBanned;

				if (data.data) {
					this.userData.type = data.data.type;
					this.userData.country = data.data.country;
				}
				
				this.hasPlan = data.hasPlan;
				this.planData.name = data.planData.name;
				this.planData.date = data.planData.date ? moment(data.planData.date).format('YYYY-MM-DD') : null;
				this.planData.price = data.planData.price;
				this.planData.duration = data.planData.duration;
			}).finally(() => {
				this.loading = false;
			})
		},

		getUserType() {
			switch (this.userData.type) {
			case '1': return this.$t('UserInfo.types.student');
			case '2': return this.$t('UserInfo.types.teacher');
			case '3': return this.$t('UserInfo.types.parent');
			default: return this.$t('UserInfo.types.undefined')
			}
		},

		moment
	},

	created() {
		this.fetchUserInfo();
	}
}
</script>

<style>

</style>