import { useRestore } from '@/configs/global.config';
import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		sets: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/sets').then(({ data }) => {
				const storeData = data.filter(c => !useRestore.sets ? !c.deletedAt : true);
				commit(types.STORE_SETS, storeData);
				return storeData;
			})
		},
		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/sets/${slug}`, configs).then(({ data }) => data)
		},
		create({ commit }, { name, isFeatured, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.post('/sets', { name, isFeatured }, configs).then(({ data }) => data);
		},
		update({ commit }, { id, name, isFeatured, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.post(`/sets/${id}`, { name, isFeatured, _method: 'PUT' }, configs).then(({ data }) => data);
		},
		delete({ commit }, { id }) {
			return axios.delete(`/sets/${id}`)
		},
		restore({ commit }, { id }) {
			return axios.patch(`/sets/${id}`)
		},
	},

	mutations: {
		[types.STORE_SETS](state, sets) {
			state.sets = sets;
		}
	},

	getters: {
		getSetById: state => id => state.sets.find(c => c.id === id),
		getSetBySlug: state => slug => state.sets.find(c => c.slug === slug),
	}
}